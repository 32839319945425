@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);
@font-face {
  font-family: "netron";
  src: local("netron"),
    url(/static/media/BIGJOHN.676a8ca7.otf) format("truetype");
  font-weight: bold;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #39bce6 !important;
  background-image: url(/static/media/bubbles-bg-1.587dfceb.png);
  background-repeat: repeat;
  background-position: center;
  /* background-attachment: fixed; */
  -webkit-animation: bg-moving 10s infinite;
          animation: bg-moving 10s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;

}

.rectangle {
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 191.29vh;
  height: 25vh;
  background: white;
  position: absolute;
}

html {
  scroll-behavior: smooth;

}

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.7);
}

* {
  transition: all 1s ease;
}

@-webkit-keyframes bg-moving {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 100;
  }

}

@keyframes bg-moving {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 100;
  }

}



h2,
h3,
h4,
h5,
h6,
.btn-size,
.increment-btn {
  text-shadow: #000 0 0 2px, #000 0 0 2px, #000 0 0 2px, #000 0 0 2px,
    #000 0 0 2px, #000 0 0 2px;
  color: white;
}

h1 {
  letter-spacing: 3px;
  padding: 50px 0px;
  color: white;
  text-align: center;
}

.title {
  text-align: center;
  font-size: 25px;
}

.frame {
  width: 100%;
}

@-webkit-keyframes example {
  0% {
    background-color: red;
  }

  25% {
    background-color: purple;
  }

  50% {
    background-color: gray;
  }

  100% {
    background-color: purple;
  }
}

@keyframes example {
  0% {
    background-color: red;
  }

  25% {
    background-color: purple;
  }

  50% {
    background-color: gray;
  }

  100% {
    background-color: purple;
  }
}

.alert {
  color: yellow;
}

/* The element to apply the animation to */
.bg-animation {
  background-color: red;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.home-col {
  vertical-align: middle;
}

.home-col-h2 {
  color: red;
  text-align: left;
}

.home-col>h3 {
  padding: 10px 0px;
  color: white;
  font-size: 35px;
}

.home-col-p {
  color: white;
  font-size: 20px;
  letter-spacing: 2px;
}

img {
  width: 100%;
}

.story-section>h1 {
  color: red;
  font-size: 30px;
}

.story-section>p {
  color: white;
  font-size: 20px;
  text-align: center;
  /* padding: 10px 100px; */
  line-height: 30px;
  letter-spacing: 3px;
}

.team-section>div:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);

}

.team-section>div img:hover {
  box-shadow: 3px 3px 5px 1px #000;
}

.community {
  font-family: "Cairo";
  text-align: center;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 15vh;
  letter-spacing: 1px;
  line-height: 0
}
.text-center {
    text-align: center;
    -webkit-margin-start: auto;
            margin-inline-start: auto;
}

.home-section-one h1 {
    color: white;
    text-align: left;
    font-weight: 600;
    font-size: 35px;

}

.home-section-one p {
    color: white;
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    padding: 1px;
}

.background-color-sections {
    background-color: rgba(135, 206, 235, 0.7);
    margin: 50px auto;
    border-radius: 10px;
    box-shadow: 1px 1px 10px 4px #00d9ff;
    padding-right: 20px;
}

.background-color-section {
    background-color: rgba(135, 206, 235, 0.7);
    margin: 0px 0px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px 4px #00d9ff;
}

.home-section-one img {
    width: 100%;
    max-width: 550px;
    border-radius: 20px;
}

.home-section-one p {
    color: white;
    text-align: left;
    font-size: 18px;
}

ul.list {
    color: white;
    list-style-type: square;
    font-size: 15px;
    text-align: left;
    list-style-type: none;
}

.table {
    color: white;
}

.table th {
    border: 1px solid black !important;
    color: white;
    font-weight: 600;

    text-align: center;
    font-size: large;

    /* Or right or left */


}

.table td {
    border-width: 2px;
    border-style: solid;
    border-color: black;
    text-align: left;
    font-weight: 200px;
    font-size: large;
    /* box-shadow: 1px 1px 10px 4px #00d9ff; */
    /* display: none; */
}

.img-box {
    display: flex;
    justify-content: center;

}

.img-box img {
    max-width: 200px;
    margin: 10px;
}

.border-div {
    /* border: 3px solid white; */
    background-color: deepskyblue;
    padding: 5px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);

}

.wec-para {
    color: white !important;
    font-size: 20px !important;
    text-align: center;
}

.black-para {
    color: black !important;
    font-size: 20px !important;
    text-align: center;
}

.attribute {
    background-image: url(/static/media/purple.45594a4a.jpg);
    background-size: 100% 100%;
}

.mobile img {
    border-radius: 10px;
}

.mobile {
    display: none;
}

.laptop {
    display: block;
}

@media (max-width:992px) {
    .wec-para {
        color: black !important;
        font-size: 20px !important;

    }
}

.bg-image {
    background-image: url(/static/media/purple.45594a4a.jpg);
    background-size: 100% 100%;


}



.bg-banner1 {
    background-image: url(/static/media/bg-2.d6c43213.png);
    background-size: 100% 100%;
    height: 80vh;
}

@media (max-width:480px) {
    .wec-para {
        color: black !important;
        font-size: 20px !important;

    }

    .bg-banner1 {
        background-image: url(/static/media/bg-3-mobile.05f50b15.png);
        background-size: 100% 100%;
        background-position: center right;
        background-size: cover;
    }

    .mobile {
        display: block;
    }

    .laptop {
        display: none;
    }
}


.swiper-image img {
    width: 100%;
    max-width: 150px;
}
:root {
  --theme-color: #e9579f;
}

.navbar .socials i {
  color: white;
  font-size: 15px;
}

.navbar .socials i:hover {
  color: red;
  font-size: 13px;
}

.navbar a {
  font-size: 20px;
  font-weight: 600;
  color: white !important;
  border-top: 1px solid #39bce6;
  margin-right: 25px;

}

.navbar a:hover {
  border-top: 1px solid black;
  color: black !important;
}

.nav-btn {
  font-size: 20px;
  font-weight: 600;
  color: white !important;
  border-top: 1px solid #39bce6;
  background-color: transparent;
  border: none;
  margin: auto;
  margin-right: 4px;
}


.navbar-brand a>img {
  width: 135px;
}

.navbar-nav {
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 0px;
  margin-right: 50px;
}

.nav-item {
  margin: auto 8px;
}

.navbar {
  /* background-color: black; */
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  background-color: #39bce6;
}

.discord {
  width: 3.6%;
  height: 3.6%;
  top: 20px;
  right: 190px;
  position: fixed;
}

.logonav {
  height: 100;
  width: 100;
  top: 0;
  position: fixed;
  margin: auto;
  margin-left: -150px;
  margin-top: -28px;


}



.rsLogo {
  height: 40px;
  width: 40px;
  margin-left: -100px;
  position: relative;
  right: -140px;
  overflow: hidden;




}

@media (max-width:480px) {
  .navbar {
    position: relative;
    margin-left: 0px;
    margin-bottom: -30px;
  }

  .logonav {
    position: relative;
    margin-left: 0px;
    margin-top: -9px;
    left: -80px;
    top: -20px;


  }

  .navbar-toggler {
    position: relative;
    right: -7px;
  }

  .navbar-brand a>img {
    width: 90px;
  }

  .rsLogo {
    margin-left: -50px;
    right: -60px;
  }

  .discord {
    top: 0px;
    left: 0px;
    width: 12%;
    height: 12%;
    position: relative;
  }

}
.team-section img {
    width: 100%;
    max-width: 250px;
    border-radius: 100%;
    /* margin: 0px 30px; */
}

.img-fluid {
    border: 2px solid black;

}

.shop {
    background-image: url(/static/media/desktop_shop_v3.845901f4.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.plus {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: -260px;
    margin-left: -225px;
}

.equals {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: -895px;
    margin-left: 350px;
}

.eqShark {
    width: 30%;
    position: relative;

    min-block-size: 50vh;
    max-height: 500vh;
    right: 0px;
}

.mutantShark {
    width: 30%;
    position: relative;
    top: -275px;
    right: -770px;
    max-height: 50vh;

}

.lilJawn {
    width: 100%;
    position: relative;
    top: -420px;
    right: -100px;
    max-height: 100vh;

}

@media (max-width:480px) {

    .shop {
        background-image: url(/static/media/shop.630e9c25.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
    }
}

.bg-team {
    background-image: url(/static/media/teambg.45594a4a.jpeg);
    background-size: 100% 100%;
    /* height: 80vh; */
}
#FAQ a {
  color: #eb6634;
  text-decoration: underline;
  font-size: 15px;
}

.inlineLeft {
  padding: 10px 10px;
  font-weight: bold !important;
  box-shadow: none;
  max-width: 90%;
  color: white;
  font-size: 20px;
  float: left;
  width: 90%;
  text-align: left;

}

.inlineRight {
  padding: 0px 0px;
  font-size: 45px;
  text-shadow: 0px 0px 19px rgba(0, 0, 0, 0.3);
  color: black;
  cursor: pointer;
  font-weight: bolder;
  box-shadow: none;
  /* float: right; */
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}

.accContent {
  color: black;
  text-align: left;
  font-size: 15px;
  background: transparent;
  margin-top: -10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 30px 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

}

.FAQ-head {
  font-size: 30px;
  color: white;
  text-align: center;
}

.small-para {
  padding: 10px;
  color: black;
  font-size: 12px;
  text-align: center;
}

.clear {
  clear: both;
}

.accordion-item {
  background-color: transparent;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  background: transparent;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.bg-faq {
  background-image: url(/static/media/purple.45594a4a.jpg);
  background-size: 100% 100%;
  border-radius: 10px;
  max-width: 1140px;
}
.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    /* border-top: 2px solid white; */

    margin-right: 0;
}

.event-list p {
    font-size: 15px;
}

.bg-team {
    background-image: url(/static/media/teambg.45594a4a.jpeg);
    background-size: 100% 100%;
    /* height: 80vh; */
}

.event-list h5 {
    font-size: 20px;
    color: white;
}

.rm {
    position: relative;
    width: 100;
    height: 100;
    right: -250px;
}

.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    /* border-right: 2px dashed #dee2e6; */
    top: 0;
}

.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    color: white;
    right: 0;
    width: 75px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}

@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 45px;
    }

    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}

.bg-soft-primary {
    background-color: #91c2ed !important;
}

.bg-soft-success {
    background-color: #91c2ed !important;
}

.bg-soft-danger {
    background-color: #91c2ed !important;
}

.bg-soft-warning {
    background-color: #91c2ed !important;
}

.card {
    /* border: 1px solid white; */
    margin-bottom: 24px;
    background-color: transparent;
}

.pink-div-head {
    background-color: #f5a9c7;
    margin-bottom: -10px;
    padding: 10px;
}

.pink-div-para {
    margin: 0px;
    background-color: #ef729e;
    padding: 10px;
}


.blue-div-head {
    background-color: #b4d1e3;
    margin-bottom: -10px;
    padding: 10px;
}

.blue-div-para {
    margin: 0px;
    background-color: #85b2d1;
    padding: 10px;
}

.yellow-div-head {
    background-color: #edd37e;
    margin-bottom: -10px;
    padding: 10px;
}

.yellow-div-para {
    margin: 0px;
    background-color: #e3b521;
    padding: 10px;
}

.purple-div-head {
    background-color: #c7acd6;
    margin-bottom: -10px;
    padding: 10px;
}

.purple-div-para {
    margin: 0px;
    background-color: #a177b6;
    padding: 10px;
}

.box-shad {
    box-shadow: 0px 0px 8px 1px black;
}

#roadmap .card {
    border: none !important;

}

@media (max-width: 480px) {
    .rm {
        position: relative;
        right: -5px;
        width: 100vh;
        height: 65vh;


    }


}
.social-icon{
    color: white;
    font-size: 30px;
    margin: 10px;
}
.spanNFT {
  color: white;
  font-size: 15px;
}

.float-right {
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  display: flex;
  float: right;
}

.mint-heading {
  font-size: 32px;
  color: white;
  font-weight: 600;
  /* font-weight: 500; */
  line-height: 1.2;
}

.float-div {
  display: flex;
  padding: 0;
  margin: 0;
}

.image {
  width: 100%;
  border-radius: 20px;

}

@media only screen and (min-width:996px) {
  #Buynft .image {
    min-width: 400px;
  }
}

.mint-mid {
  color: white;
  font-size: 2rem;
  text-align: center;
}

.btn-size {
  font-size: 30px;
}

.mint-col>h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  float: left;
  color: white;
  vertical-align: middle;
}

.Buynft {
  padding-top: 70px;
}

.btngroup {
  float: right;
}

.increment-btn {
  font-size: 30px;
  color: white;
  font-weight: bolder;
  border-radius: 20px;
  background-color: #00bcd4;
  border: none;
  padding: 10px;
  margin: 10px;
  cursor: pointer;

}

.connect-button {
  font-size: 30px;
  color: white;
  font-weight: bolder;
  border-radius: 20px;
  background-color: #00bcd4;
  border: none;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}


.increment-btn:hover {
  color: white;
  /* border-radius: 50px; */
  font-size: 30px;

}

.spanNFT {
  font-size: 25px;
}

.border-btm {
  padding-top: 30px;
  border-bottom: 2px solid black;
}

.mint-btn {
  max-width: 400px;
  margin: auto;
  padding: 15px 30px;
  background: #91c2ed;
  border-radius: 15px;
  border: 1px solid #000;
  border-bottom: 5px solid black;
  border-top: 1px solid black;
  cursor: pointer;
}

.mint-btn:hover {


  background-color: #91c2ed;
}






/* From cssbuttons.io */
.c-button {
  color: #000;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  padding: 0.9em 1.6em;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
}

.c-button--gooey {
  color: #06c8d9;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 4px solid #06c8d9;
  border-radius: 0;
  position: relative;
  transition: all 700ms ease;
}

.c-button--gooey .c-button__blobs {
  height: 100%;
  -webkit-filter: url(#goo);
          filter: url(#goo);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: -3px;
  right: -1px;
  z-index: -1;
}

.c-button--gooey .c-button__blobs div {
  background-color: #06c8d9;
  width: 34%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  -webkit-transform: scale(1.4) translateY(125%) translateZ(0);
          transform: scale(1.4) translateY(125%) translateZ(0);
  transition: all 700ms ease;
}

.c-button--gooey .c-button__blobs div:nth-child(1) {
  left: -5%;
}

.c-button--gooey .c-button__blobs div:nth-child(2) {
  left: 30%;
  transition-delay: 60ms;
}

.c-button--gooey .c-button__blobs div:nth-child(3) {
  left: 66%;
  transition-delay: 25ms;
}

.c-button--gooey:hover {
  color: #fff;
}

.c-button--gooey:hover .c-button__blobs div {
  -webkit-transform: scale(1.4) translateY(0) translateZ(0);
          transform: scale(1.4) translateY(0) translateZ(0);
}
.slider-vertical {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 100px;
    margin-bottom: 100px;
    width: 20%;

}

.slider-vertical img {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.bg-banner {
    background-image: url(/static/media/banner.422f997f.JPEG);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 98vh;

}

.logo-section img {
    width: 100%;
}

.logo-section {
    width: 60%;
    margin: auto;

}

.vertical-slider {
    margin-top: 100px;
    display: flex;
    height: 695px;
    width: 300px;
}

.slider-image {
    height: 200px;
}



@media (min-width:500px) {
    .btn {
        background-color: blue;
        color: white;
        border-radius: 5px;
        padding: 10px;
        font-size: 15px;
        border: none;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        width: 150px;
        height: 50px;
    }
}

@media (max-width:480px) {
    .logo-section {
        width: 55vh;
        position: relative;

        margin: auto;
        margin-top: 70px;
    }

    .bg-banner {
        background-image: url(/static/media/banner.422f997f.JPEG);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 55vh;
        width: 100%;

    }

    .vertical-slider {
        margin-top: 100px;
        display: flex;
        height: 100%;
        width: 100%;
    }

    .slider-image {
        height: 100%;
        width: 100%;
    }

    .btn-font {
        font-size: 20px;
        margin: 10px;
    }
}
.center-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
.pete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
