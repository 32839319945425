@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

@font-face {
  font-family: "netron";
  src: local("netron"),
    url("../fonts/BIGJOHN.otf") format("truetype");
  font-weight: bold;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #39bce6 !important;
  background-image: url(../images/bubbles-bg-1.png);
  background-repeat: repeat;
  background-position: center;
  /* background-attachment: fixed; */
  animation: bg-moving 10s infinite;
  animation-timing-function: linear;
  animation-direction: alternate;

}

.rectangle {
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 191.29vh;
  height: 25vh;
  background: white;
  position: absolute;
}

html {
  scroll-behavior: smooth;

}

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.7);
}

* {
  transition: all 1s ease;
}

@keyframes bg-moving {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 100;
  }

}



h2,
h3,
h4,
h5,
h6,
.btn-size,
.increment-btn {
  text-shadow: #000 0 0 2px, #000 0 0 2px, #000 0 0 2px, #000 0 0 2px,
    #000 0 0 2px, #000 0 0 2px;
  color: white;
}

h1 {
  letter-spacing: 3px;
  padding: 50px 0px;
  color: white;
  text-align: center;
}

.title {
  text-align: center;
  font-size: 25px;
}

.frame {
  width: 100%;
}

@keyframes example {
  0% {
    background-color: red;
  }

  25% {
    background-color: purple;
  }

  50% {
    background-color: gray;
  }

  100% {
    background-color: purple;
  }
}

.alert {
  color: yellow;
}

/* The element to apply the animation to */
.bg-animation {
  background-color: red;
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.home-col {
  vertical-align: middle;
}

.home-col-h2 {
  color: red;
  text-align: left;
}

.home-col>h3 {
  padding: 10px 0px;
  color: white;
  font-size: 35px;
}

.home-col-p {
  color: white;
  font-size: 20px;
  letter-spacing: 2px;
}

img {
  width: 100%;
}

.story-section>h1 {
  color: red;
  font-size: 30px;
}

.story-section>p {
  color: white;
  font-size: 20px;
  text-align: center;
  /* padding: 10px 100px; */
  line-height: 30px;
  letter-spacing: 3px;
}

.team-section>div:hover {
  transform: scale(1.1);

}

.team-section>div img:hover {
  box-shadow: 3px 3px 5px 1px #000;
}

.community {
  font-family: "Cairo";
  text-align: center;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 15vh;
  letter-spacing: 1px;
  line-height: 0
}