:root {
  --theme-color: #e9579f;
}

.navbar .socials i {
  color: white;
  font-size: 15px;
}

.navbar .socials i:hover {
  color: red;
  font-size: 13px;
}

.navbar a {
  font-size: 20px;
  font-weight: 600;
  color: white !important;
  border-top: 1px solid #39bce6;
  margin-right: 25px;

}

.navbar a:hover {
  border-top: 1px solid black;
  color: black !important;
}

.nav-btn {
  font-size: 20px;
  font-weight: 600;
  color: white !important;
  border-top: 1px solid #39bce6;
  background-color: transparent;
  border: none;
  margin: auto;
  margin-right: 4px;
}


.navbar-brand a>img {
  width: 135px;
}

.navbar-nav {
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 0px;
  margin-right: 50px;
}

.nav-item {
  margin: auto 8px;
}

.navbar {
  /* background-color: black; */
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  background-color: #39bce6;
}

.discord {
  width: 3.6%;
  height: 3.6%;
  top: 20px;
  right: 190px;
  position: fixed;
}

.logonav {
  height: 100;
  width: 100;
  top: 0;
  position: fixed;
  margin: auto;
  margin-left: -150px;
  margin-top: -28px;


}



.rsLogo {
  height: 40px;
  width: 40px;
  margin-left: -100px;
  position: relative;
  right: -140px;
  overflow: hidden;




}

@media (max-width:480px) {
  .navbar {
    position: relative;
    margin-left: 0px;
    margin-bottom: -30px;
  }

  .logonav {
    position: relative;
    margin-left: 0px;
    margin-top: -9px;
    left: -80px;
    top: -20px;


  }

  .navbar-toggler {
    position: relative;
    right: -7px;
  }

  .navbar-brand a>img {
    width: 90px;
  }

  .rsLogo {
    margin-left: -50px;
    right: -60px;
  }

  .discord {
    top: 0px;
    left: 0px;
    width: 12%;
    height: 12%;
    position: relative;
  }

}