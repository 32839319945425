.text-center {
    text-align: center;
    margin-inline-start: auto;
}

.home-section-one h1 {
    color: white;
    text-align: left;
    font-weight: 600;
    font-size: 35px;

}

.home-section-one p {
    color: white;
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    padding: 1px;
}

.background-color-sections {
    background-color: rgba(135, 206, 235, 0.7);
    margin: 50px auto;
    border-radius: 10px;
    box-shadow: 1px 1px 10px 4px #00d9ff;
    padding-right: 20px;
}

.background-color-section {
    background-color: rgba(135, 206, 235, 0.7);
    margin: 0px 0px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px 4px #00d9ff;
}

.home-section-one img {
    width: 100%;
    max-width: 550px;
    border-radius: 20px;
}

.home-section-one p {
    color: white;
    text-align: left;
    font-size: 18px;
}

ul.list {
    color: white;
    list-style-type: square;
    font-size: 15px;
    text-align: left;
    list-style-type: none;
}

.table {
    color: white;
}

.table th {
    border: 1px solid black !important;
    color: white;
    font-weight: 600;

    text-align: center;
    font-size: large;

    /* Or right or left */


}

.table td {
    border-width: 2px;
    border-style: solid;
    border-color: black;
    text-align: left;
    font-weight: 200px;
    font-size: large;
    /* box-shadow: 1px 1px 10px 4px #00d9ff; */
    /* display: none; */
}

.img-box {
    display: flex;
    justify-content: center;

}

.img-box img {
    max-width: 200px;
    margin: 10px;
}

.border-div {
    /* border: 3px solid white; */
    background-color: deepskyblue;
    padding: 5px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);

}

.wec-para {
    color: white !important;
    font-size: 20px !important;
    text-align: center;
}

.black-para {
    color: black !important;
    font-size: 20px !important;
    text-align: center;
}

.attribute {
    background-image: url(../../images/purple.jpg);
    background-size: 100% 100%;
}

.mobile img {
    border-radius: 10px;
}

.mobile {
    display: none;
}

.laptop {
    display: block;
}

@media (max-width:992px) {
    .wec-para {
        color: black !important;
        font-size: 20px !important;

    }
}

.bg-image {
    background-image: url(../../images/purple.jpg);
    background-size: 100% 100%;


}



.bg-banner1 {
    background-image: url(../../images/bg-2.png);
    background-size: 100% 100%;
    height: 80vh;
}

@media (max-width:480px) {
    .wec-para {
        color: black !important;
        font-size: 20px !important;

    }

    .bg-banner1 {
        background-image: url(../../images/bg-3-mobile.png);
        background-size: 100% 100%;
        background-position: center right;
        background-size: cover;
    }

    .mobile {
        display: block;
    }

    .laptop {
        display: none;
    }
}


.swiper-image img {
    width: 100%;
    max-width: 150px;
}