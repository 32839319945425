.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    /* border-top: 2px solid white; */

    margin-right: 0;
}

.event-list p {
    font-size: 15px;
}

.bg-team {
    background-image: url(../../images/teambg.jpeg);
    background-size: 100% 100%;
    /* height: 80vh; */
}

.event-list h5 {
    font-size: 20px;
    color: white;
}

.rm {
    position: relative;
    width: 100;
    height: 100;
    right: -250px;
}

.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    /* border-right: 2px dashed #dee2e6; */
    top: 0;
}

.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    color: white;
    right: 0;
    width: 75px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}

@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 45px;
    }

    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}

.bg-soft-primary {
    background-color: #91c2ed !important;
}

.bg-soft-success {
    background-color: #91c2ed !important;
}

.bg-soft-danger {
    background-color: #91c2ed !important;
}

.bg-soft-warning {
    background-color: #91c2ed !important;
}

.card {
    /* border: 1px solid white; */
    margin-bottom: 24px;
    background-color: transparent;
}

.pink-div-head {
    background-color: #f5a9c7;
    margin-bottom: -10px;
    padding: 10px;
}

.pink-div-para {
    margin: 0px;
    background-color: #ef729e;
    padding: 10px;
}


.blue-div-head {
    background-color: #b4d1e3;
    margin-bottom: -10px;
    padding: 10px;
}

.blue-div-para {
    margin: 0px;
    background-color: #85b2d1;
    padding: 10px;
}

.yellow-div-head {
    background-color: #edd37e;
    margin-bottom: -10px;
    padding: 10px;
}

.yellow-div-para {
    margin: 0px;
    background-color: #e3b521;
    padding: 10px;
}

.purple-div-head {
    background-color: #c7acd6;
    margin-bottom: -10px;
    padding: 10px;
}

.purple-div-para {
    margin: 0px;
    background-color: #a177b6;
    padding: 10px;
}

.box-shad {
    box-shadow: 0px 0px 8px 1px black;
}

#roadmap .card {
    border: none !important;

}

@media (max-width: 480px) {
    .rm {
        position: relative;
        right: -5px;
        width: 100vh;
        height: 65vh;


    }


}