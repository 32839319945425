.slider-vertical {
    transform: rotate(90deg);
    margin-top: 100px;
    margin-bottom: 100px;
    width: 20%;

}

.slider-vertical img {
    transform: rotate(-90deg);
}

.bg-banner {
    background-image: url(../../images//banner.JPEG);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 98vh;

}

.logo-section img {
    width: 100%;
}

.logo-section {
    width: 60%;
    margin: auto;

}

.vertical-slider {
    margin-top: 100px;
    display: flex;
    height: 695px;
    width: 300px;
}

.slider-image {
    height: 200px;
}



@media (min-width:500px) {
    .btn {
        background-color: blue;
        color: white;
        border-radius: 5px;
        padding: 10px;
        font-size: 15px;
        border: none;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        width: 150px;
        height: 50px;
    }
}

@media (max-width:480px) {
    .logo-section {
        width: 55vh;
        position: relative;

        margin: auto;
        margin-top: 70px;
    }

    .bg-banner {
        background-image: url(../../images//banner.JPEG);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 55vh;
        width: 100%;

    }

    .vertical-slider {
        margin-top: 100px;
        display: flex;
        height: 100%;
        width: 100%;
    }

    .slider-image {
        height: 100%;
        width: 100%;
    }

    .btn-font {
        font-size: 20px;
        margin: 10px;
    }
}