.team-section img {
    width: 100%;
    max-width: 250px;
    border-radius: 100%;
    /* margin: 0px 30px; */
}

.img-fluid {
    border: 2px solid black;

}

.shop {
    background-image: url(../../images/desktop_shop_v3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.plus {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: -260px;
    margin-left: -225px;
}

.equals {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: -895px;
    margin-left: 350px;
}

.eqShark {
    width: 30%;
    position: relative;

    min-block-size: 50vh;
    max-height: 500vh;
    right: 0px;
}

.mutantShark {
    width: 30%;
    position: relative;
    top: -275px;
    right: -770px;
    max-height: 50vh;

}

.lilJawn {
    width: 100%;
    position: relative;
    top: -420px;
    right: -100px;
    max-height: 100vh;

}

@media (max-width:480px) {

    .shop {
        background-image: url(../../images/shop.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
    }
}

.bg-team {
    background-image: url(../../images/teambg.jpeg);
    background-size: 100% 100%;
    /* height: 80vh; */
}