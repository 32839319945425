#FAQ a {
  color: #eb6634;
  text-decoration: underline;
  font-size: 15px;
}

.inlineLeft {
  padding: 10px 10px;
  font-weight: bold !important;
  box-shadow: none;
  max-width: 90%;
  color: white;
  font-size: 20px;
  float: left;
  width: 90%;
  text-align: left;

}

.inlineRight {
  padding: 0px 0px;
  font-size: 45px;
  text-shadow: 0px 0px 19px rgba(0, 0, 0, 0.3);
  color: black;
  cursor: pointer;
  font-weight: bolder;
  box-shadow: none;
  /* float: right; */
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}

.accContent {
  color: black;
  text-align: left;
  font-size: 15px;
  background: transparent;
  margin-top: -10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 30px 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

}

.FAQ-head {
  font-size: 30px;
  color: white;
  text-align: center;
}

.small-para {
  padding: 10px;
  color: black;
  font-size: 12px;
  text-align: center;
}

.clear {
  clear: both;
}

.accordion-item {
  background-color: transparent;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  background: transparent;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.bg-faq {
  background-image: url(../../images/purple.jpg);
  background-size: 100% 100%;
  border-radius: 10px;
  max-width: 1140px;
}